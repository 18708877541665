import {
  createContext,
  useState,
  useEffect,
  useContext
} from "react";
import lodash from "lodash";

// Api
import { getLeadsDistributionList } from "actions"

// Context
import { GlobalContext } from "contexts";

const UseLeadsDistributionSateContext = createContext(null);
const UseLeadsDistributionMethodContext = createContext(null);

const LEADS_STATUS = {
  menunggu: "menunggu",
  ditangani: "ditangani",
}

export const LeadsDistributionProvider = ({ children }) => {
  const { setShowLoading } = useContext(GlobalContext);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [leadStatus, setLeadStatus] = useState("menunggu");
  const [listLeads, setListLeads] = useState([])

  const handleGetLeads = async () => {
    try {
      setShowLoading(true)
      const query = `?page=${page}&lead_status=${leadStatus}`
      const response = await getLeadsDistributionList(query)
      const totalItem = response?.headers?.["x-total"]
      const totalPage = lodash.ceil(totalItem / 10) || 0

      const newData = response?.data?.data ?? []

      setTotalPage(totalPage)
      setListLeads(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setShowLoading(false)
    }
  }

  const handleNextPage = () => {
    if (page === totalPage) return;

    setPage(val => val + 1)
  }
  const handlePrevPage = () => {
    if (page === 1) return;

    setPage(val => val - 1)
  }

  const handleChangeLeadStatus = (status) => setLeadStatus(status)

  useEffect(() => {
    handleGetLeads()
  }, [page, leadStatus])

  return (
    <UseLeadsDistributionSateContext.Provider value={{
      LEADS_STATUS,
      listLeads,
      leadStatus,
      totalPage,
      page
    }}>
      <UseLeadsDistributionMethodContext.Provider value={{
        setLeadStatus,
        handleNextPage,
        handlePrevPage,
        handleChangeLeadStatus
      }}>
        {children}
      </UseLeadsDistributionMethodContext.Provider>
    </UseLeadsDistributionSateContext.Provider>
  )
}

//Custom hooks for the values
export const useLeadsDistributionState = () => {
  const ctx = useContext(UseLeadsDistributionSateContext);

  if (!ctx) {
    throw new Error("useLeadsDistributionState must be used within the ERProvider");
  }

  return ctx;
};

//Custom hooks for the functions
export const useLeadsDistributionMethod = () => {
  const ctx = useContext(UseLeadsDistributionMethodContext);

  if (!ctx) {
    throw new Error("useLeadsDistributionMethod must be used within the ERProvider");
  }

  return ctx;
};