import { createContext, useContext, useEffect, useState, useCallback, useMemo } from "react";
import _ from "lodash"
import { Form, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { toast } from 'react-toastify'

//Actions
import {
  getListingKantor,
  getDetailImages,
  searchListProperty,
  getMarketingAgent,
  exportListing,
  archiveListing,
  unarchiveListing,
  getListingKantorInactive,
  getDetailListing,
  activedListing,
  deleteListing,
  getHotListings,
  deleteHotListings
} from "actions";

import { GlobalContext } from "contexts";

//Functions
const DetailListingCtx = createContext(null);

export const DetailListingProvider = ({ children }) => {
  const navigate = useNavigate()
  const { profile, setShowLoading } = useContext(GlobalContext)
  const [isOpenModalImages, setIsOpenModalImages] = useState(false);

  //banner inactive state
  const [totalInactive, setTotalInactive] = useState(0)
  const [loading, setLoading] = useState(false)

  const [myListing, setMyListing] = useState({
    loading: false,
    data: [],
    page: 1,
    total: 0
  })

  const [listDetail, setListDetail] = useState(null)
  const [isFetchingDetailListing, setIsFetchingDetailListing] = useState(false)
  const [detailImages, setDetailImages] = useState({
    data: [],
    loading: false
  })

  //Search handler
  const [search, setSearch] = useState('')
  const [agent, setAgent] = useState(null)
  const [openModalFilter, setOpenModalFilter] = useState(null)
  const [searchAndFilterPage, setSearchAndFilterPage] = useState(1)
  const [filter, setFilter] = useState("active")

  const isSearchingOrFiltering = useMemo(() => {
    return search || agent
  }, [search, agent])

  const [agents, setAgents] = useState({
    data: [],
    fetching: true,
    error: ""
  })

  const filterAndSearchAction = async (query = "") => {
    try {
      setMyListing((val) => ({ ...val, loading: true }))
      const listings = await searchListProperty(`?_for=office${query}`);
      const totalItem = listings?.headers?.["x-total"];
      const newData = listings?.data?.data ?? []

      if (newData.length) {
        setMyListing((val) => ({ ...val, data: newData, total: _.ceil(totalItem / 10) || 0 }))
      } else {
        setMyListing((val) => ({ ...val, data: [], total: 0 }))
      }

      if (newData.length && !listDetail) {
        handleGetDetailListing(newData[0]?.slug)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setMyListing((val) => ({ ...val, loading: false, page: 1 }))
      }, 500)
    }
  }

  const fetchBySearchKeyword = useCallback(_.debounce(async (value, agent, _filter) => {
    setMyListing((val) => ({ ...val, page: 1 }))
    let query = `?page=1${value ? "&keywords=" + value : ''}${agent ? "&user_id=" + agent : ""}`

    switch (_filter) {
      case "terjual":
        query += "&property_type_id=1"
        break;
      case "tersewa":
        query += "&property_type_id=2"
        break;
      default:
        break;
    }

    await getAllMyListings(query)
  }, [1500]), [])

  const searchHandler = (e) => {
    const __value = e.target.value || "";
    setSearch(__value)
    fetchBySearchKeyword(__value.trim().replaceAll(" ", "+"), agent?.id, filter)
  }


  const getValue = (str = "") => _.get(listDetail, str)

  const getAllMyListings = async (inputQuery, reset = false) => {
    try {
      setMyListing((val) => ({ ...val, loading: true }))
      let query = `?page=${myListing.page}`

      if (search && !reset) {
        query += `&keywords=${search}`
      }

      if (agent?.id && !reset) {
        query += `&user_id=${agent?.id}`
      }

      switch (filter) {
        case "terjual":
          query += "&property_type_id=1&is_archived=true"
          break;
        case "tersewa":
          query += "&property_type_id=2&is_archived=true"
          break;
        default:
          break;
      }

      const listings =
        filter === 'inactive' ? await getListingKantorInactive(inputQuery ? inputQuery : query)
          : filter === "hot-listing" ? await getHotListings(inputQuery ? inputQuery : query)
            : await getListingKantor(inputQuery ? inputQuery : query);

      const totalItem = listings?.headers?.["x-total"];

      if (filter === 'inactive') {
        setTotalInactive(Number(totalItem));
      }

      if (listings?.data?.data?.length) {
        setMyListing((val) => ({ ...val, data: listings.data?.data, total: _.ceil(totalItem / 10) || 0 }))
      } else {
        setMyListing((val) => ({ ...val, data: [], total: 0 }))
      }

      if (listings?.data?.data?.length && !listDetail) {
        handleGetDetailListing(listings?.data?.data[0]?.slug)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setMyListing((val) => ({ ...val, loading: false }))
      }, 500)
    }
  }

  const _getDetailInactiveTotal = async (link) => {
    try {
      const listingsInactive = await getListingKantorInactive(`?page=1`);
      const totalItem = listingsInactive?.headers?.["x-total"];
      setTotalInactive(Number(totalItem));
    } catch (error) {
      console.log(error)
    }
  }

  const getInactiveListing = async (inputQuery) => {
    try {
      setMyListing((val) => ({ ...val, loading: true }))
      let query = `?page=1`

      const listings = await getListingKantorInactive(inputQuery ? inputQuery : query);
      const totalItem = listings?.headers?.["x-total"];
      if (filter === 'inactive') {
        setTotalInactive(Number(totalItem));
      }
      if (listings?.data?.data?.length) {
        setMyListing((val) => ({ ...val, data: listings.data?.data, total: _.ceil(totalItem / 10) || 0 }))
      } else {
        setMyListing((val) => ({ ...val, data: [], total: 0 }))
      }

      if (listings?.data?.data?.length && !listDetail) {
        setListDetail(listings?.data?.data[0])
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setMyListing((val) => ({ ...val, loading: false }))
      }, 500)
    }
  }

  const _getActivedListing = async (slug) => {
    try {
      setLoading(true);
      await activedListing(slug);

      await getAllMyListings()
      setListDetail(null)
      toast.success("Listing berhasil di aktifkan.");
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

  const _getMarkerListing = async (slug) => {
    try {
      setLoading(true);
      await archiveListing(slug);
      await getAllMyListings()
      setListDetail(null)

      toast.success("Listing berhasil di arsipkan.");
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

  const _getDeleteListing = async (slug) => {
    try {
      setLoading(true);
      await deleteListing(slug);
      await getAllMyListings()
      setListDetail(null)
      toast.success("Listing berhasil di hapuskan.");
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }
  const _getDetailImages = async (link) => {
    try {
      setDetailImages((val) => ({ ...val, loading: true }))

      const images = await getDetailImages(link)

      setDetailImages((val) => ({ ...val, data: images.data || [] }))
    } catch (error) {
      setDetailImages((val) => ({ ...val, data: [] }))
      console.log(error)
    } finally {
      setTimeout(() => {
        setDetailImages((val) => ({ ...val, loading: false }))
      }, 500)
    }
  }

  const goToUbahListing = async () => {
    const url = "/upload-listing/" + listDetail?.slug;
    navigate(url)
  }

  const getAgents = async () => {
    try {
      setAgents(val => ({ ...val, fetching: true, error: "" }))

      const list = await getMarketingAgent("?page=1&_limit=99999")

      const newData = list?.data ?? []

      setAgents(val => ({ ...val, data: newData }))
    } catch (error) {
      setAgents(val => ({ ...val, error }))
    } finally {
      setAgents(val => ({ ...val, fetching: false }))
    }
  }

  const exportToExcel = async () => {
    try {
      const created_at_to = dayjs().format("YYYY-MM-DD")
      const created_at_from = dayjs().subtract(1, "year").format("YYYY-MM-DD")

      const query = `?company_ids[]=${profile?.company?.id}&created_at[from]=${created_at_from}&created_at[to]=${created_at_to}`
      const response = await exportListing(query)

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      const fileName = `Listing C21 ${profile?.company?.name} ${created_at_from} - ${created_at_to}.xlsx`
      link.setAttribute('download', fileName); // or any other extension
      document.body.appendChild(link);
      link.click();

    } catch (error) {
      console.log(error)
    } finally {

    }
  }

  const terapkanFilter = async (_agent) => {
    setAgent(_agent)
    setSearchAndFilterPage(1)
    await filterAndSearchAction(`${search ? "&keywords=" + search : ''}${_agent?.id ? "&user_id=" + _agent?.id : ""}${filter === "active" ? "" : filter === "terjual" ? "&property_type_id=1" : "&property_type_id=2"}`)
  }
  const resetFilter = () => {
    setSearchAndFilterPage(1)
    setSearch('')
    setAgent(null)

    getAllMyListings("", true)
  }

  const handleDeleteHotListing = async (hotListingId, callback) => {
    try {
      setShowLoading(true)
      await deleteHotListings(hotListingId)
      getAllMyListings()
      _getDetailInactiveTotal()
      toast.success(`Hot listing dengan id ${hotListingId} berhasil di hapus.`);
    } catch (error) {
      console.log(error)
      toast.success(error);
    } finally {
      callback && callback()
      setShowLoading(false)
    }
  }

  const handleGetDetailListing = async (slug) => {
    try {
      setIsFetchingDetailListing(true)
      const res = await getDetailListing(slug);

      setListDetail(res?.data ?? null)
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetchingDetailListing(false)
    }
  }

  useEffect(() => {
    getAllMyListings()
    _getDetailInactiveTotal()
  }, [myListing.page, filter])

  useEffect(() => {
    const links = _.get(listDetail, "links.property_images")
    links && _getDetailImages(links)
  }, [listDetail])

  useEffect(() => {
    if (filter === "hot-listing") {
      setSearch("")
    }
  }, [filter])

  useEffect(() => {
    getAgents()
  }, [])

  return (
    <DetailListingCtx.Provider value={{
      myListing,
      isOpenModalImages,
      listDetail,
      detailImages,
      search,
      agents,
      openModalFilter,
      agent,
      searchAndFilterPage,
      isSearchingOrFiltering,
      company: profile?.company?.name,
      filter,
      loading,
      totalInactive,
      terapkanFilter,
      resetFilter,
      setAgent,
      setOpenModalFilter,
      getValue,
      setListDetail,
      setIsOpenModalImages,
      getAllMyListings,
      setMyListing,
      goToUbahListing,
      searchHandler,
      setSearchAndFilterPage,
      exportToExcel,
      setFilter,
      setTotalInactive,
      _getActivedListing,
      _getMarkerListing,
      _getDeleteListing,
      handleDeleteHotListing,
      handleGetDetailListing
    }}>
      {children}
    </DetailListingCtx.Provider>
  )
}

//Custom hooks for the values
export const useDetailListingCtx = () => {
  const ctx = useContext(DetailListingCtx);

  if (!ctx) {
    throw new Error("useERState must be used within the DetailListingProvider");
  }

  return ctx;
};
