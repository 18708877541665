import {
  BrowserRouter
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';

//Context
import { GlobalProvider } from "./contexts"

//Routes
import Routes from "./routes";

const App = () => {
  return (
    <BrowserRouter>
      <GlobalProvider>
          <Routes />
      </GlobalProvider>
      <ToastContainer />
    </BrowserRouter >
  )
}

export default App;
