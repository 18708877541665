import React, { useContext, useMemo } from 'react'
import _ from "lodash"

//SVG
import { ReactComponent as UsersIcon } from "../../../../assets/users-svg.svg";
import { ReactComponent as MoneyIcon } from "../../../../assets/money.svg";
import { ReactComponent as LockIcon } from "../../../../assets/lock.svg";
import { ReactComponent as CCIcon } from "../../../../assets/credit-card.svg";
import { ReactComponent as ModuloIcon } from "../../../../assets/modulo.svg";

//Helpers
import wordCounter from "utils/wordCounter"

//Components
import {
  ButtonSvgIcon,
  CSelectV1,
  CTextInputV1,
  CTextInputV2,
  CTextInputV3,
  CTextareaInputV1,
  MapFieldV1,
  Divider
} from "../../../../components";

//Context
import { UploadListingContext } from "../../../../contexts"

import PointCard from "pages/dashboard/upload-listing/components/atom/PointCard"
import TextLengthCounter from "pages/dashboard/upload-listing/components/atom/TextLengthCounter"
import FormulaJudul from "pages/dashboard/upload-listing/components/atom/FormulaJudul"

function IdentitasListing() {
  const {
    form,
    onSelectForm,
    onChangeForm,
    manualSelectForm,
    propertyCategories,
    provinces,
    cities,
    areas,
    ownerShipIds,
    selectMap,
    onChangeStreet,
    onChangeKP,
    unitPrice,
    score,
    isTanah,
    slug,
    descriptionValidation
  } = useContext(UploadListingContext)

  const isSelectedUnPublishedMap = useMemo(() => {
    return !!form.unpublish_map.latitude && !!form.unpublish_map.longitude
  }, [form.unpublish_map])

  const isSelectedPublishedMap = useMemo(() => {
    return !!form.publish_map.latitude && !!form.publish_map.longitude
  }, [form.publish_map])

  const descriptionClass = useMemo(() => {
    if(!descriptionValidation(form.description)) return " !border-[none] !border-red-0 "
    return ""
  }, [form.description])

  const handleToLowerCase = (str = "") => {
    if (!str) {
      return ""
    }

    return str.replace(/[^\w\s]/gi, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
  }

  const dataUnitPrice = () => {
    if (!unitPrice.data.length) return [];

    if (form.property_category.id !== 4 && form.property_type_id === 2) {
      return unitPrice.data.filter(d => d.name !== "M2")
    }
    if (form.property_category.id === 4 && form.property_type_id === 1) {
      return [
        {
          "id": 1,
          "name": "Per Unit",
          "links": {
            "self": "/public/v1/unit-prices/7"
          }
        },
        {
          "id": 7,
          "name": "Per M2",
          "links": {
            "self": "/public/v1/unit-prices/7"
          }
        }]
    }

    return unitPrice.data.map(d => {
      return d.id === 8 ? {
        "id": 8,
        "name": "Per M2",
        "links": {
          "self": "/public/v1/unit-prices/7"
        }
      } : d
    })
  }

  const isM2 = form.min_rent.name == "Per M2"

  const renderDurasi = () => {
    if (form.property_type_id === 2) {
      return <CSelectV1
        className="_field"
        placeholder="Pilih durasi harga"
        label="Durasi Sewa"
        name="unit_price_id"
        value={form.unit_price_id}
        options={dataUnitPrice()}
        displayName="name"
        valueName="id"
        onSelect={onSelectForm}
      />
    }

    if (form.property_category.id === 4 || form.property_category.id === 12) {
      return <CSelectV1
        className="_field"
        placeholder="Pilih durasi harga"
        label="Unit"
        name="unit_price_id"
        value={form.unit_price_id}
        options={dataUnitPrice()}
        displayName="name"
        valueName="id"
        onSelect={onSelectForm}
      />
    }

    return null
  }

  return (
    <div className='form-container identitas-listing-container'>
      {/* Marketing Associate */}
      <div className='marketing-associate'>
        <p className='assosiate-name'>Marketing associate dipilih : {" "}
          {
            form.agent.full_name ?
              <span>{form.agent.full_name}</span>
              :
              <span>Belum ada Marketing associate yang dipilih....</span>
          }
        </p>
        <p className='skor-listing'>Skor listing kamu : {" "} <button className='score-card'><span className='score-point'>{score}</span><span className='score-text'>poin</span></button></p>
      </div>

      {/* Listing */}
      <div className='ilf-container listing-container'>
        <div className='g-section-text'>
          <p>Listing</p>
        </div>
        <div className='g-section-form listing-form'>
          <div className='privasi-listing'>
            <p className='title'>Privasi listing</p>
            <div className='listing-btn-wrap'>
              <ButtonSvgIcon
                action={() => manualSelectForm({ name: "property_privacy_id", value: 1 })}
                className={`listing-button ${form.property_privacy_id === 1 ? "!text-white" : ""} mr-[16px] ${form.property_privacy_id == 1 ? "selected" : ""}`}
                icon={<UsersIcon />}
                text="Public"
                // disabled={!!slug}
              />
              <ButtonSvgIcon
                action={() => manualSelectForm({ name: "property_privacy_id", value: 2 })}
                className={`listing-button  ${form.property_privacy_id === 2 ? "!text-white" : ""} ${form.property_privacy_id == 2 ? "selected" : ""}`}
                icon={<LockIcon />}
                text="Private"
                // disabled={!!slug}
              />
            </div>
          </div>
          <div className='jenis-listing'>
            <p className='title'>Jenis listing <PointCard point={isTanah ? 7.5 : 5} /></p>
            <div className='listing-btn-wrap'>
              <ButtonSvgIcon
                action={() => manualSelectForm({ name: "property_type_id", value: 1 })}
                className={`listing-button ${form.property_type_id === 1 ? "!text-white" : ""} mr-[16px] ${form.property_type_id == 1 ? "selected" : ""}`}
                icon={<MoneyIcon />}
                text="Dijual"
                disabled={!!slug}
              />
              <ButtonSvgIcon
                action={() => manualSelectForm({ name: "property_type_id", value: 2 })}
                className={`listing-button ${form.property_type_id === 2 ? "!text-white" : ""} ${form.property_type_id == 2 ? "selected" : ""}`}
                icon={<CCIcon />}
                text="Disewakan"
                disabled={!!slug}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Jenis dan gambaran umum property */}
      <div className='ilf-container'>
        <div className='g-section-text'>
          <p>Jenis dan gambaran umum properti</p>
          <p className='mandatory-text'>* tidak boleh kosong</p>
        </div>
        <div className='g-section-form jdgup-form'>
          <CSelectV1
            name="property_category"
            className="_field"
            placeholder="Pilih jenis properti"
            value={form.property_category}
            label="Jenis properti"
            options={propertyCategories}
            displayName="name"
            valueName="id"
            onSelect={onSelectForm}
            disabled={!!slug}
            ComponentLabel={<PointCard point={isTanah ? 7.5 : 5} />}
          />
          <CTextInputV1
            name="title"
            onChange={onChangeForm}
            placeholder="Tuliskan judul listing"
            value={form.title}
            label="Judul listing"
            maxLength={65}
            onBlur={() => manualSelectForm({ name: "title", value: handleToLowerCase(form.title) })}
            ComponentLabel={<PointCard point={isTanah ? 20 : 10} />}
          />
          <TextLengthCounter
            leftText={`min. 40 karakter - maks 65 karakter`}
            current={form?.title?.length ?? 0}
            max="/65"
            fullFilled={form?.title?.length >= 40}
          />

          <Divider classname={'_divider mb-[16px] mt-[16px]'} />
          <FormulaJudul
            header="Formula judul yang direkomendasikan: "
            list={[
              "tidak menggunakan simbol;",
              "tidak menggunakan kata “Dijual” dan “Disewa”;",
              "judul listing terdiri atas, “Apa” + “dimana” + “mengapa”. Contoh, Rumah 3 Lantai di Jl Bunga Matahari Dekat Area Perkantoran."
            ]}
          />

          <div className='custom-label flex items-center justify-between'>
            <p>Deskripsi</p>
            <p className='mandatory-text'>* tidak boleh kosong</p>
          </div>
          <CTextareaInputV1
            name="description"
            onChange={onChangeForm}
            placeholder="Deskripsikan disini..."
            value={form.description}
            textareaClassName={`${descriptionClass}`}
          />
          <TextLengthCounter
            leftText={`diwajibkan min. 20 karakter`}
            current={form?.description?.length ?? 0}
            max="/20"
            fullFilled={(form?.description?.length ?? 0) >= 20}
          />
          <Divider classname={'_divider mb-[16px] mt-[16px]'} />

          <FormulaJudul
            header="Apa yang perlu dituliskan dalam deskripsi: "
            list={[
              "Deskripsi minimal 20 karakter.",
              "Deskripsi tidak boleh berisi karakter spesial.",
              "Deskripsi tidak boleh berisi karakter spesial yang beruturut-turut, contoh: “.....“, “,,,,,“, dsb.",
              'Deksripsi tidak boleh berisi angka berturut-turut seperti nomor handphone dan harga, contoh: “087532134567”, atau “2.000.000.000"'
            ]}
          />
        </div>
      </div>

      {/* Alamat property yang tidak dipublikasikan */}
      <div className='ilf-container'>
        <div className='g-section-text'>
          <p>Alamat properti yang tidak dipublikasikan</p>
          <p className='additional-text'>Hanya member association yang dapat melihat alamat ini</p>
          <p className='mandatory-text'>* tidak boleh kosong</p>
        </div>
        <div className='g-section-form apytd-form'>
          <p className='gsf-title'>Provinsi <PointCard point={isTanah ? 7.5 : 5} /></p>
          <CSelectV1
            name="provinces"
            className="_field"
            placeholder="Pilih provinsi"
            value={form.provinces}
            options={provinces}
            displayName="name"
            valueName="id"
            onSelect={onSelectForm}
          />
          <CSelectV1
            className="_field"
            placeholder="Pilih kota"
            label="Kota"
            name="cities"
            value={form.cities}
            options={cities}
            displayName="name"
            valueName="id"
            onSelect={onSelectForm}
          />
          <CSelectV1
            className="_field"
            placeholder="Pilih area"
            label="Area"
            name="areas"
            value={form.areas}
            options={areas}
            displayName="name"
            valueName="id"
            onSelect={onSelectForm}
          />
          <MapFieldV1
            pinMapStreet={form.unpublish_map_street}
            selectedStreet={form.unpublish_map.street}
            onChange={(e) => onChangeStreet({ name: "unpublish_map", value: e.target.value })}
            action={(map) => selectMap("unpublish_map", "unpublish_map_street", map)}
            isSelected={isSelectedUnPublishedMap}
          />
        </div>
      </div>

      {/* Alamat property yang dipublikasikan */}
      <div className='ilf-container'>
        <div className='g-section-text'>
          <p>Alamat properti yang dipublikasikan</p>
          <p className='additional-text'>Hanya member association yang dapat melihat alamat ini</p>
          <p className='mandatory-text'>* tidak boleh kosong</p>
        </div>
        <div className='g-section-form apytd-form'>
          <MapFieldV1
            CustomTitle={<p className='gsf-title mb-[8px]'>Lokasi di peta <PointCard point={isTanah ? 7.5 : 5} /></p>}
            pinMapStreet={form.publish_map_street}
            selectedStreet={form.publish_map.street}
            onChange={(e) => onChangeStreet({ name: "publish_map", value: e.target.value })}
            action={(map) => selectMap("publish_map", "publish_map_street", map)}
            isSelected={isSelectedPublishedMap}
          />
        </div>
      </div>

      {/* Harga dan komisi */}
      <div className='ilf-container'>
        <div className='g-section-text'>
          <p>Harga dan komisi</p>
          <p className='mandatory-text'>* tidak boleh kosong</p>
        </div>
        <div className='g-section-form apytd-form'>
          <CTextInputV3
            className="_field"
            type="number"
            label="Harga/unit"
            suffixText={"Rp"}
            value={form.price}
            placeholder="0"
            onChange={(value) => manualSelectForm({ name: "price", value })}
            name="price"
          />
          {renderDurasi()}
          <div>
            <p className='dble-input-label'>Komisi penjualan</p>
            <div className='double-input'>
              <CTextInputV2
                className="_field double_field1"
                type="number"
                suffixPosition="right"
                suffixIcon={<ModuloIcon />}
                value={form.commission_rate}
                onChange={onChangeKP}
                name="commission_rate"
                placeholder="0"
              />
              <CTextInputV3
                className={`_field double_field2 ${isM2 ? "hide-field" : ""}`}
                type="number"
                suffixText={"Rp"}
                value={form.commission_amount}
                onChange={onChangeForm}
                name="commission_amount"
                placeholder="0"
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      {/* Identitas Owner */}
      <div className='ilf-container'>
        <div className='g-section-text'>
          <p>Identitas Owner</p>
          <p className='optional-text'>* boleh dikosongkan optional</p>
        </div>
        <div className='g-section-form apytd-form'>
          <CTextInputV1
            className="_field"
            placeholder="Tuliskan nama owner"
            label="Nama owner"
            value={form.property_owner_attributesname}
            onChange={onChangeForm}
            name="property_owner_attributesname"
          />
          <CTextInputV1
            type="number"
            className="_field"
            placeholder="Tulis nomor whatsapp owner"
            label="Whatsapp"
            value={form.property_owner_attributesphone}
            onChange={onChangeForm}
            name="property_owner_attributesphone"
          />
        </div>
      </div>

      {/* Status Kepemilikan */}
      <div className='ilf-container'>
        <div className='g-section-text'>
          <p>Status kepemilikan</p>
          <p className='mandatory-text'>* tidak boleh kosong</p>
        </div>
        <div className='g-section-form apytd-form'>
          <p className='gsf-title'>Status kepemilikan <PointCard point={isTanah ? 15 : 6} /></p>
          <CSelectV1
            className="_field"
            placeholder="Pilih status kepemilikan"
            name="ownership_status"
            value={form.ownership_status}
            options={ownerShipIds}
            displayName="name"
            valueName="id"
            onSelect={onSelectForm}
          />
        </div>
      </div>
    </div>
  )
}

export default IdentitasListing