import React, { useState, useRef, useContext } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs'

// Styles
import Styles from './list.module.scss'
import './list.scss'

// Components
import ButtonV2 from 'components/atomic/button-v2'
import ButtonV2LeftIcon from 'components/atomic/button-v2-left-icon'
import Table from '../table'
import { ModalLoader } from "components/organism"

// Assets
import { ReactComponent as Calendar } from 'assets/calendar.svg'

import useListInvoice from "./useListInvoice"
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import { GlobalContext } from "contexts";

const List = () => {
  const {profile} = useContext(GlobalContext)
  const customDropdownRef = useRef(null);
  const { data, status, setStatus, COLLUMNS, date, setDate, loading } = useListInvoice()
  const [openDate, setOpenDate] = useState(false)

  const onChange = async (dates) => {
    setDate(dates)
    setOpenDate(false)
  };

  useOutsideAlerter(customDropdownRef, () => setOpenDate(false))
  return (
    <div className={Styles['wrapper']}>
      <div>
        <div className={Styles['header']}>
          <p className={Styles['title']}>Invoice</p>
          <p className={Styles['sub-title']}>Selamat datang kembali, {profile?.full_name}!</p>

          <div className={Styles['actions-wrapper']}>
            <div className={Styles['wrapper-button']}>
              <button onClick={() => setStatus("menunggu")} className={`${Styles['button']} ${!!(status === "menunggu") && Styles['active']}`}><span>Belum Bayar</span></button>
              <button onClick={() => setStatus("lunas")} className={`${Styles['button']} ${!!(status === "lunas") && Styles['active']}`}><span>Lunas</span></button>
              {/* <button onClick={() => setStatus("diputihkan")} className={`${Styles['button']} ${!!(status === "diputihkan") && Styles['active']}`}><span>Diputihkan</span></button> */}
            </div>

            <div id='invoice-wrapper-periode' className={`${Styles['wrapper-periode']}`} ref={customDropdownRef}>
              <ButtonV2LeftIcon Icon={Calendar} text={date ? dayjs(date).format("MM YYYY") : "Pilih periode"} onClick={() => setOpenDate(true)} />
              {/* <ButtonV2 text="Download Excel" /> */}
              {openDate ? <DatePicker
                selected={date}
                onChange={onChange}
                startDate={date}
                inline
                showMonthYearPicker
              /> : ""}
            </div>
          </div>
        </div>

        <div className={Styles['wrapper-content']}>
          <Table data={data} status={status} COLLUMNS={COLLUMNS} />
        </div>
      </div>
      <div className={Styles['wrapper-pagination']}>
      </div>
      {loading && <ModalLoader open={true} />}
    </div>
  )
}

export default List