import React, { useEffect, useState, useMemo } from 'react'

//Actions
import { uploadListingAction } from "../actions";

const maxPage = 1000;

function useUploadListing() {
  //Marketing agent
  const [marketingAgent, setMarketingAgent] = useState([]);
  const [loadingMarketingAgent, setLoadingMarketingAgent] = useState(true);

  //Property types
  const [propertyTypes, setPropertyTypes] = useState([]);

  //Property category
  const [propertyCategories, setPropertyCategories] = useState([]);

  const [propertyPrivacies, setPropertyPrivacies] = useState([]);
  const [ownerShipIds, setOwnershipIds] = useState([]);

  //provinces
  const [provinces, setProvinces] = useState([]);
  const [tempProvince, setTempProvince] = useState([]);
  const [page, setPage] = useState(1);
  const [fetchingProvince, setFetchingProvince] = useState(true);


  // fasilitas sekitar
  const [dataFasilitasPribadi, setDataFasilitasPribadi] = useState([])
  const [dataFasilitasSekitar, setDataFasilitasSekitar] = useState([])
  
  //Initializer
  const [initializer, setInitializer] = useState([])
  
  const getMarketingAgentHandler = async () => {
    try {
      setLoadingMarketingAgent(true)
      const res = await uploadListingAction.getMarketingAgent()
      const newMAList = res?.data ?? [];

      setMarketingAgent(newMAList)
    } catch (error) {
      console.log(error)
    } finally{
      setLoadingMarketingAgent(false)
    }
  }

  const getPropertyTypesHandler = async () => {
    try {
      await uploadListingAction
        .getPropertyTypes()
        .then(res => {
          setPropertyTypes(res?.data ?? [])
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getPropertyBaseOnTypes = async (type) => {
    try {
      await uploadListingAction
        .getPropertyBaseOnType(type)
        .then(res => {
          setPropertyTypes(res?.data ?? [])
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getPropertyCategoriesHandler = async (count = 1) => {
    try {
      const getPropCategory = await uploadListingAction.getPropertyCategory(`?page=${count}&_limit=50`)

      if(getPropCategory?.data?.length){
        if (count == 1) {
          setPropertyCategories(getPropCategory.data ?? [])
        } else {
          setPropertyCategories([...propertyCategories, ...getPropCategory.data])
        }
        await getPropertyCategoriesHandler(count + 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getPropertyPrivaciesHandler = async () => {
    try {
      await uploadListingAction
        .getPropertyPrivacies()
        .then(res => {
          setPropertyPrivacies(res?.data ?? [])
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getProvincesByCountryId = async () => {
    try {
      setPage(page + 1)
      const query = `?page=${page}`
      await uploadListingAction
        .getProvincesByCountriId(undefined, query)
        .then(res => {
          if (res?.data?.length) {
            const temp = [...tempProvince, ...res.data]
            setTempProvince(temp)
          } else {
            setFetchingProvince(false)
            setPage(1)
          }
        })
        .catch(error => {
          console.log(error)
          setFetchingProvince(false)
          setPage(1)
        })
    } catch (error) {
      console.log(error)
      setFetchingProvince(false)
      setPage(1)
    }
  }


  const getOwnershipStatus = async () => {
    try {
      await uploadListingAction
        .getOwnershipStatus()
        .then(res => {
          setOwnershipIds(res?.data ?? [])
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const uploadListing = async (property_id, payload, callback) => {
    try {
      await uploadListingAction
        .uploadListing(property_id, payload)
        .then(res => {
          callback && callback(true)
        })
        .catch(error => {
          callback && callback(false, error)
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getFacilities = async (type, action, count) => {
    try{
      const facilities = await uploadListingAction.getFacilities(`?kind=${type}`)
      facilities?.data?.length && action && action(facilities.data.map(d => ({...d, facility_id: d.id, id: null})))
    } catch(error){
      console.log(error)
    }
  }

  const getInitializer = async () => {
    try{
      const initializer = await uploadListingAction.getInitializer()
      if(initializer.data){
        setInitializer(initializer.data)
      }
    }catch(error){
      console.log(error)
    }
  }

  const filteredCategories = useMemo(() => {
    if(!propertyCategories.length) return []
    
    return propertyCategories.filter(f => ![11, 14, 15, 16, 17].includes(f.id))
  }, [propertyCategories])

  // provinces
  useEffect(() => {
    //maxPage is to stop infinite fetching
    if (fetchingProvince && page <= maxPage) {
      getProvincesByCountryId()
    } else {
      setProvinces(tempProvince)
    }
  }, [tempProvince, fetchingProvince])

  useEffect(() => {
    getMarketingAgentHandler()
    getPropertyTypesHandler()
    getPropertyCategoriesHandler()
    getPropertyPrivaciesHandler()
    getOwnershipStatus()
    getFacilities("home", setDataFasilitasPribadi)
    getFacilities("environment", setDataFasilitasSekitar)
    getInitializer()

    uploadListingAction.get()
  }, [])

  return {
    marketingAgent,
    propertyTypes,
    propertyCategories: filteredCategories,
    propertyPrivacies,
    provinces,
    ownerShipIds,
    dataFasilitasPribadi,
    dataFasilitasSekitar,
    initializer,
    loadingMarketingAgent,
    getPropertyBaseOnTypes,
    uploadListing
  }
}

export default useUploadListing