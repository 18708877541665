import React, { useContext, useMemo } from 'react'
import {
  Routes as RouteList,
  Route,
  Navigate
} from "react-router-dom";

//Context
import { GlobalContext } from "contexts";

//Pages
import {
  UploadListing,
  Dashboard,
  EReporting,
  Login,
  KreditKonsumen,
  ListListing,
  DashboardLeads,
  KeyMetricOfficeDashboard,
  Marketing,
  Invoice,
  LeadsManagement,
  IpafEvent,
  ProgressTrackerRumah123,
  One21Event,
  LeadsDistribution
} from "pages";

import InvoiceList from "pages/dashboard/invoice/components/list"
import InvoiceDetail from "pages/dashboard/invoice/components/detail"
import PaymentResult from "pages/dashboard/invoice/components/payment-result"

//Auth Route
import AuthRoute from "routes/AuthRoute";
import PrivateDashboardList from "routes/private-routes/PrivateDashboardList"

const Routes = () => {
  const { profile, showInvoice, showIpaf, showOne21Event } = useContext(GlobalContext);

  const isMB = useMemo(() => {
    return profile?.role?.name == "Member Broker"
  }, [profile])

  return (
    <div className='pages-container'>
      <RouteList>
        <Route path="/" element={<AuthRoute><Dashboard /></AuthRoute>}>
          <Route path="/ereporting" element={<EReporting></EReporting>} />
          <Route path="/" element={<ListListing></ListListing>} />
          <Route path="/upload-listing" element={<UploadListing></UploadListing>} />
          <Route path="/upload-listing/:slug" element={<UploadListing></UploadListing>} />
          {/* <Route path="/kredit-konsumen" element={<KreditKonsumen />} /> */}
          <Route path="/leads-my-ciputra" element={<DashboardLeads />} />
          <Route path="/leads-management" element={<LeadsManagement />} />
          <Route path="/key-metric-office-dashboard" element={<KeyMetricOfficeDashboard />} />
          <Route path="/marketing" element={<Marketing />} />
          <Route path="/progress-tracker" element={<ProgressTrackerRumah123 />} />
          <Route path="/leads-distribution" element={<LeadsDistribution />} />
          {showIpaf && <Route path="/ipaf-event" element={<IpafEvent />} />}
          {showOne21Event && <Route path="/one21-event" element={<One21Event />} />}
          {showInvoice && <Route path="/invoice" element={<Invoice />}>
            <Route path="/invoice" element={<InvoiceList />} />
            <Route path="/invoice/payment-result/success" element={<PaymentResult />} />
            <Route path="/invoice/payment-result/failed" element={<PaymentResult />} />
            <Route path="/invoice/:id" element={<InvoiceDetail />} />
            <Route path="*" element={<Navigate to="/invoice" />} />
          </Route>}
        </Route>
        <Route path="/login" element={<Login></Login>} />
        <Route
          path="*"
          element={isMB ? <Navigate to="/ereporting" /> : <Navigate to="/" />}
        />
      </RouteList>
    </div>
  )
}

export default Routes;