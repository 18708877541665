import Resizer from "react-image-file-resizer";

const resizeFile = (file, maxWidth, maxHeight, returnType = "file") =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      returnType
    );
  });

export default resizeFile