import React from 'react'
import dayjs from 'dayjs';
import _ from "lodash"
import { Oval } from "react-loader-spinner";
import { useNavigate } from 'react-router-dom';

//Style
import "./style.scss"

// Button Icon
import { ButtonSvgIcon } from "components";
import { CSelectV1 } from "components/atomic/Input";
import Button from "components/atomic/Button";
import ButtonV2 from "components/atomic/button-v2";

// Components
import ListHotListing from './ListHotListing';

//Assets
import { ReactComponent as NextIcon } from "assets/next.svg";
import { ReactComponent as PreviousIcon } from "assets/previous.svg";
import { ReactComponent as SellBadge } from "assets/sell-badge.svg";
import { ReactComponent as RentBadge } from "assets/rent-badge.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { ReactComponent as UnduhExcel } from "assets/unduh-excel.svg";
import { ReactComponent as WarningIcon } from "assets/warning.svg";
import { ReactComponent as HotListingLogo } from "assets/fire.svg";

//Context
import { useDetailListingCtx } from "../Context"

//Utils
import { nominalBilangan } from "utils/currencyFormatter";

function ListListing() {
  const navigate = useNavigate();
  const {
    myListing,
    setMyListing,
    setListDetail,
    listDetail,
    setAgent,
    search,
    agent,
    searchHandler,
    agents,
    terapkanFilter,
    resetFilter,
    exportToExcel,
    company,
    setFilter,
    filter,
    totalInactive,
    handleGetDetailListing
  } = useDetailListingCtx();


  const handleTanggalPelaporan = (date = "") => {
    if (!date) return "";
    let result = dayjs(date).format("D MMM, YYYY")

    if (result == "Invalid Date") {
      const newDate = date.replace(/-/g, '/')
      result = dayjs(newDate).format("D MMM, YYYY")
    }

    return result
  }

  const handleArea = (locations) => {
    try {
      if (locations.length) {
        return locations[0].area.name
      }
      return ""
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const onSelectHandler = async (e, options, valueName) => {
    const { value } = e.target
    const __agent = options.filter((option) => {
      return option[valueName] == value
    })[0]

    setAgent(__agent)
    await terapkanFilter(__agent)
  }

  return (
    <div className='list-listing'>
      <div className='wrapper-header-list-listing'>
        <div>
          <p className='list-listing-header'>Daftar Listing</p>
          <p className='list-listing-sub-header'>Century 21 {company}</p>
        </div>
        <div className='button-wrapper-list-listing'>
          <button className={`btn-filter-list-listing ${filter === "active" && "active"}`} onClick={() => setFilter("active")}>Aktif</button>
          <button className={`btn-filter-list-listing temp ${filter === "inactive" && "active"}`} onClick={() => setFilter("inactive")}>Tidak Aktif</button>
          <button className={`btn-filter-list-listing ${filter === "terjual" && "active"}`} onClick={() => setFilter("terjual")}>Terjual</button>
          <button className={`btn-filter-list-listing ${filter === "tersewa" && "active"}`} onClick={() => setFilter("tersewa")}>Tersewa</button>
          {/* <button className={`btn-filter-list-listing ${filter === "hot-listing" && "active"}`} onClick={() => setFilter("hot-listing")}>Hot Listing <HotListingLogo className='w-[20px] h-[20px] ml-[6px]' /></button> */}
        </div>
      </div>
      {filter !== "inactive" && filter !== "hot-listing" && totalInactive > 0 &&
        <div className='flex justify-between inactive-banner-box'>
          <div className='box-banner-left'>
            <WarningIcon className='warning-icon' />
            <p className='inactive-banner-title'>Ada {totalInactive} listing yang sudah melewati masa aktif 6 bulan.</p>
          </div>
          <ButtonV2
            className='ml-[24px]'
            text="Lihat listing"
            onClick={() => setFilter("inactive")}
          />
        </div>
      }

      {
        filter === "hot-listing" ? (
          <ListHotListing />
        ) : (
          <div className='list-listing-table-wrapper'>
            <div className='flex justify-between mt-[24px] mr-[24px]'>
              <ButtonV2
                className='ml-[24px]'
                text="Buat Listing"
                Icon={PlusIcon}
                onClick={() => navigate("/upload-listing")}
                bg='black'
              />
              <ButtonV2
                className='ml-[24px]'
                text="Download Listing 1 Tahun Terakhir"
                onClick={exportToExcel}
                Icon={UnduhExcel}
              />
            </div>

            <div className='search-n-fiter-wrapper'>
              <div className='custom-search-box'>
                <span>Cari berdasarkan </span>
                <input disabled={myListing.loading} value={search} placeholder='Judul, alamat, deskripsi' onChange={searchHandler} />
              </div>
              <CSelectV1
                className='marketing-agent-name mr-[8px]'
                placeholder="Marketing"
                options={agents.data}
                displayName="full_name"
                valueName="id"
                name="ma_id"
                value={agent || {}}
                onSelect={onSelectHandler}
                disabled={myListing.loading}
              />
              <Button
                text='Clear'
                className='ml-[20px] text-white'
                disabled={(!agent && !search) || myListing.loading}
                action={resetFilter}
              />
            </div>

            <div className='table-pagination-wrapper'>
              {myListing.loading ? (
                <div className='w-[100%] flex justify-center items-center' style={{ height: "100vh" }}>
                  <Oval
                    height={60}
                    width={60}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                </div>
              ) : (
                <>
                  {/** TABLE */}
                  <div className='list-listing-table'>
                    <div className='table-header'>
                      <div className='table-row'>
                        <div className='table-data'><span>Tanggal dibuat</span></div>
                        <div className='table-data'><span>Marketing</span></div>
                        <div className='table-data'><span>Area</span></div>
                        <div className='table-data'><span>Harga</span></div>
                        <div className='table-data'><span>Jenis Properti</span></div>
                      </div>
                    </div>

                    <div className='table-body'>
                      {myListing.data.length ? myListing.data.map((list, key) => {
                        return (
                          <div onClick={() => handleGetDetailListing(list?.slug)} className={`table-row ${listDetail?.id == list.id ? "selected" : ""}`} key={`my-listing-table-${key}`}>
                            <div className='table-data'><span className={`h-4 w-[32px] flex items-center mr-1 justify-center px-0.5 py-1.5 rounded-[100px] bg-yellow-0 !text-xs !font-sfpro-bold !text-white invisible ${!!list.is_pjp && "!visible"}`}>PJP</span><span>{handleTanggalPelaporan(list.created_at)}</span></div>
                            <div className='table-data'><span>{list?.user?.full_name ?? ""}</span></div>
                            <div className='table-data'><span>{handleArea(list?.summary?.property_locations ?? [])}</span></div>
                            <div className='table-data'><span>Rp {nominalBilangan(parseInt(list?.price ?? 0))}</span></div>
                            <div className='table-data'><span className='flex items-center'>{_.get(list, "property_type.id") == 1 ? <SellBadge className='mr-[4px]' /> : <RentBadge className='mr-[4px]' />} {list?.property_category?.name}</span></div>
                          </div>
                        )
                      }) : null}
                    </div>
                  </div>

                  {/** PAGINATION */}
                  <div className='table-pagination'>
                    <div className='pagination-wrapper whitespace-nowrap'>
                      <ButtonSvgIcon
                        text="Sebelumnya"
                        icon={<PreviousIcon />}
                        className="prev-btn mr-[32px]"
                        disabled={myListing.loading || (myListing.page == 1)}
                        action={() => {
                          setMyListing((val) => ({ ...val, page: val.page - 1 }))
                        }
                        }
                      />
                      <p className='pagination-text mr-[32px]'><span>{myListing.page}</span> dari <span>{myListing.total}</span> halaman</p>
                      <ButtonSvgIcon
                        text="Selanjutnya"
                        icon={<NextIcon />}
                        className="next-btn"
                        disabled={myListing.loading || (myListing.data.length == 0 || Number(myListing.page) == Number(myListing.total))}
                        action={() => {
                          setMyListing((val) => ({ ...val, page: val.page + 1 }))
                        }
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )
      }
    </div>
  )
}

export default ListListing;