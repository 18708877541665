export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const PUBLIC_V1 = 'public/v1'
export const PUBLIC_API = PUBLIC_V1

export const ADMIN_V1 = 'admin/v1'
export const ADMIN_API = ADMIN_V1

export const COMPANIES_LIST = `${PUBLIC_API}/companies`
export const TRSANCTION_LIST = `${PUBLIC_API}/transaction-reports`
export const USERS = `${PUBLIC_API}/users`
export const CHECK_EMAIL_LOGIN = `${PUBLIC_API}/account/check-credentials`
export const GET_PROFILE = `${PUBLIC_API}/account/profile`;
export const CHECK_CALCULATE_COMISSION = '/transaction-reports/commission-calculate'
export const APPROVE_ALL_DATA_LISTING = '/transaction-reports/bulk-approve'
export const CANCEL_TGCC = `${PUBLIC_API}/transaction-reports/:id/cancel-tgcc`

export const AGENT_ROLE = '/transaction-reports/agent-role'
export const URL_LOGIN_WEB = `${PUBLIC_API}/account/sign-in-web`;
export const SIGN_OUT = `${PUBLIC_API}/account/sign-out`;
export const MARKETING_AGENT = `${PUBLIC_API}/module/user/all`;
export const PROPERTY_TYPES = `${PUBLIC_API}/property-types`;
export const PROPERTY_CATEGORIES = `${PUBLIC_API}/property-categories`;

export const PROPERTY_PRIVACIES = `${PUBLIC_API}/property-privacies`;
export const COUNTRIES = `${PUBLIC_API}/countries`;
export const PROVINCES = `${PUBLIC_API}/provinces`;
export const CITIES = `${PUBLIC_API}/cities`;
export const AREAS = `${PUBLIC_API}/areas`;

export const OWNERSHIP_STATUS = `${PUBLIC_API}/ownership-statuses`;
export const UPLOAD_LISTING = `${PUBLIC_API}/module/property/uploads?property_type_id=`;
export const GET_TRANSACTION_REPORT = `${PUBLIC_API}/transaction-reports`;
export const GET_KPR = `${PUBLIC_API}/kprs`;
export const UNIT_PRICE = `${PUBLIC_API}/unit-prices`;

export const FACILITIES = `${PUBLIC_API}/facilities`;
export const INITIALIZER = `utility/initializers`;
export const MY_LISTINGS = `${PUBLIC_API}/module/property/my-listings`;
export const ACTIVED_MY_LISTINGS = `${PUBLIC_API}/properties`;
export const PROPERTY_KANTOR = `${PUBLIC_API}/module/property/offices`;
export const PROPERTY_KANTOR_INACTIVE = `${PUBLIC_API}/properties/inactive-six-months`;
export const PROPERTY_SEARCH = `${PUBLIC_API}/module/property/searchs`;
export const LEADS = `${PUBLIC_API}/leads`;

//Quarterly reporting
export const KEY_METRIC_DASHBOARD = `${PUBLIC_API}/key-metrics`

//Users
export const COLLEAGUES = `${PUBLIC_API}/module/user/colleagues`;

export const INVOICE = `${PUBLIC_API}/invoices`;
export const IPAF_EVENT = `${PUBLIC_API}/ipaf-events`;
export const SUBMIT_IPAF_EVENT = `${PUBLIC_API}/ipaf-event-bookings`;

export const REQUEST_OTP = `${PUBLIC_API}/account/request-otp`;
export const VALIDATE_OTP = `${PUBLIC_API}/account/otp-validate`;
export const CREATE_NEW_PASSWORD = `${PUBLIC_API}/account/change-passwords`;

//download e reporting 
export const URL_E_REPORTING_COMPANY = `${PUBLIC_API}/reportings`;

export const TRACK_DOWNLOAD_BANNER = `${PUBLIC_API}/banner-downloads`;

export const CREATE_PJP_OFFLINE = `${PUBLIC_API}/offline-marketing-agreements`;
export const GET_RUMAH_123 = `${PUBLIC_API}/rumah123`;

// refresh rekap weekly agent
export const KEY_METRIC_DASHBOARD_ADMIN = `${ADMIN_API}/key-metrics`

export const LIST_LEADS = `${PUBLIC_API}/leads`;
export const LIST_DISTRIBUTION_LEADS = `${PUBLIC_API}/lead-distributions`;
export const LIST_MARKETING_DISTRIBUTION_LEADS = `${PUBLIC_API}/module/user/marketing-associates`;
export const SUBMIT_DISTRIBUTION_LEADS = `${PUBLIC_API}/lead-distributions/:id/assign-marketing`;

// Hot listing
export const HOT_LISTING = `${PUBLIC_API}/hot-listings`;
export const MARKETING_AGREEMENT = `${PUBLIC_API}/marketing-agreements`;