import axios from "axios";
import analytics from "init/firebaseAnalitycs"
import { logEvent } from "firebase/analytics";

//Constant
import { BASE_URL } from "../constant";

import handleError from "./handleError";
import { getToken } from "./accessToken";

export const api = (headers = {}, getDetails = false, config = {}) => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json", ...headers },
    ...config
  })

  instance.interceptors.request.use(
    (config) => {
      logEvent(analytics, "api_request", {
        method: config.method,
        url: config.url,
      })
      if (
        config.method === "get" ||
        config.method === "delete" ||
        config.data === undefined
      ) {
        config.data = true;
      }

      const newConfig = config;

      const { token } = getToken() || {};
      newConfig.headers["X-AUTH-TOKEN"] = token;

      return newConfig;
    },
    (error) => {
      return Promise.reject(error)
    }
  );


  instance.interceptors.response.use(
    response => {
      logEvent(analytics, "api_response", {
        status: response.status,
        data: response.data,
      })

      return getDetails ? response : response.data;
    },
    (error) => {
      logEvent(analytics, "api_error", {
        status: error.response.status,
        message: error.message,
      })
      return Promise.reject(handleError(error))
    }
  )

  return instance;
}
