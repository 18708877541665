import React, { useCallback } from 'react'
import Autocomplete from "react-google-autocomplete";

function GoogleMapAutoComplete({ value, placeholder, onPlaceSelected, Icon, className }) {
  
  const onAutoCompleteCallback = useCallback(
    (value) => {
      const result = {
        lat: value?.geometry?.location.lat().toString(),
        lnt: value?.geometry?.location.lng().toString(),
        address: value.formatted_address
      }
      if (typeof onPlaceSelected == "function") onPlaceSelected({...result, street: result.address})
    });

  return (
    <div className={`google-map-autocomplete ${className}`}>
      {Icon ? <Icon className="search-icon" /> : null}
      <Autocomplete
        apiKey={"AIzaSyAV06y2yu3mJx4tEBcGMisY3kxxOgg4aU4"}
        onPlaceSelected={onAutoCompleteCallback}
        types={["establishment"]}
        className="search-input"
        placeholder={placeholder}
        options={{
          // types: ["(regions)"],
          componentRestrictions: { country: "id" },
        }}
      />
    </div>
  )
}

GoogleMapAutoComplete.defaultProps = {
  className: ""
}

export default GoogleMapAutoComplete